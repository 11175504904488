import React, { useMemo, useCallback } from "react"
import { useForm } from "react-hook-form"
import Configuration from "../../Configuration"
import "../../css/forms.css"
import "./css/contactForm.css"

const ContactForm = ({ onSubmit, className = "" }) => {
  const { handleSubmit, register, formState: {errors} } = useForm()
  const submitHandler = useCallback(async (data) => {
    if(onSubmit) {
      await fetch('/', {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(data)
      })
      onSubmit(data)
    }
  }, [onSubmit])
  return <form data-netlify="true" name="contactInquiry" onSubmit={handleSubmit(submitHandler)} autoComplete="off" noValidate="noValidate" className={`${className} form contact-form`}>
    <h2>Contact Us</h2>
    <p>
      If you have questions or just want to say "Hi", drop us a message below or text us
      at <a href={`sms:+${Configuration.phoneNumber}`}>844-745-2862</a>. We'd love to hear from you.
    </p>
    <div className="contact-form__row">
      <div className="form__control">
        <label className="form__label" htmlFor="firstName">First Name</label>
        <input className={`form__input w-full ${errors.firstName ? 'form__input_error' : ''}`} type="text" id="firstName" {...register("firstName", { required: true })} />
        {errors.firstName && <p className="form__error">Invalid first name</p>}
      </div>
      <div className="form__control">
        <label className="form__label" htmlFor="lastName">Last Name</label>
        <input className={`form__input w-full ${errors.lastName ? 'form__input_error' : ''}`} type="text" id="lstName" {...register("lastName", { required: true })} />
        {errors.lastName && <p className="form__error">Invalid last name</p>}
      </div>
    </div>
    <div className="contact-form__row">
      <div className="form__control">
        <label className="form__label" htmlFor="email">Email</label>
        <input className={`form__input w-full ${errors.email ? 'form__input_error' : ''}`} type="email" id="email" {...register("email", {required: true, pattern: Configuration.emailRegex })} />
        {errors.email && <p className="form__error">Invalid email</p>}
      </div>
      <div className="form__control">
        <label className="form__label" htmlFor="phoneNumber">Phone Number</label>
        <input className={`form__input w-full ${errors.phoneNumber ? 'form__input_error' : ''}`} type="tel" id="phoneNumber" {...register("phoneNumber")} />
        {errors.phoneNumber && <p className="form__error">Invalid phone number</p>}
      </div>
    </div>
    <div className="contact-form__row">
      <div className="form__control col-span-2">
        <label className="form__label" htmlFor="firstName">Message</label>
        <textarea className={`form__input w-full ${errors.message ? 'form__input_error' : ''}`} id="message" {...register("message", { required: true })} />
        {errors.message && <p className="form__error">Invalid message</p>}
      </div>
    </div>


    <input type="submit" value="Send" className="button button_size_sm" />
    <input type="hidden" value="contactInquiry" {...register("form-name")} />

    <p hidden>
      <label>ignore: <input value="" {...register("emailAddress", {validate: v => v === ""})} /></label>
    </p>
  </form>
}

export default ContactForm
