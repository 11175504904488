import React, { useCallback } from "react";
import useContactModal from "./hooks/useContactModal";
import "@launchacademy/voyager/dist/css/theme/buttons/buttons.css";

const ContactButton = ({ children, location }) => {
  const { setModalVisibility, modal } = useContactModal({ location });
  const showContactDialog = useCallback(
    (e) => {
      e.preventDefault();
      setModalVisibility(true);
    },
    [setModalVisibility],
  );
  return (
    <>
      <a href="#" onClick={showContactDialog}>
        Contact
      </a>
      {modal}
    </>
  );
};

export default ContactButton;
