import * as React from "react";
import { Icon } from "@launchacademy/voyager";
import logo from "@launchacademy/voyager/dist/images/logo.svg";
import FooterOptInForm from "./footer/FooterOptInForm";
import Configuration from "../Configuration";
import {
  faGithub,
  faFacebookF,
  faYoutube,
  faLinkedin,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import ContactButton from "./contact/ContactButton";
import { ToastProvider } from "react-toast-notifications";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import "@launchacademy/voyager/dist/css/theme/navigation/footer.css";
import "@launchacademy/voyager/dist/css/theme/buttons/buttons.css";
import "@launchacademy/voyager/dist/css/theme/icons/icon.css";

const Footer = ({ location }) => {
  return (
    <footer className="footer">
      <div className="footer__brand">
        <img className="footer__logo" src={logo} alt="Launch Academy" height="48" width="240" />
        <ul className="footer__social-media-links">
          <li>
            <a href="https://github.com/launchacademy" aria-label="Launch Academy on GitHub">
              <Icon icon={faGithub} fixedWidth />
            </a>
          </li>
          <li>
            <a href="https://facebook.com/TheLaunchAcademy" aria-label="Launch Academy on Facebook">
              <Icon icon={faFacebookF} fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/c/Launchacademy"
              aria-label="Launch Academy on YouTube"
            >
              <Icon icon={faYoutube} fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/launch-academy-inc/"
              aria-label="Launch Academy on LinkedIn"
            >
              <Icon icon={faLinkedin} fixedWidth />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/LaunchAcademy" aria-label="Launch Academy on Twitter">
              <Icon icon={faTwitter} fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/launchacademy/"
              aria-label="Launch Academy on Instagram"
            >
              <Icon icon={faInstagram} fixedWidth />
            </a>
          </li>
        </ul>
        <div className="footer__contact-info">
          <p>
            <Icon icon={faPhone} /> <a href={`sms:+${Configuration.phoneNumber}`}>844-745-2862</a>
          </p>
          <p>
            <Icon icon={faEnvelope} />{" "}
            <a href="mailto:hello@launchacademy.com">hello@launchacademy.com</a>
          </p>
        </div>
      </div>
      <FooterOptInForm />
      <section className="footer__link-list">
        <h5 className="footer__link-list-title">Outcomes</h5>
        <ul>
          <li>
            <a href="https://static.spacecrafted.com/b13328575ece40d8853472b9e0cf2047/r/f55372eff2de479a8a5b4fdf68d68489/1/Launch%20Academy%20Boston%20Fundamentals%20of%20Web%20Development%20H1%202020.pdf">
              CIRR
            </a>
          </li>
          <li>
            <a href="https://www.switchup.org/bootcamps/launch-academy">SwitchUp</a>
          </li>
          <li>
            <a href="https://www.coursereport.com/schools/launch-academy">Course Report</a>
          </li>
        </ul>
      </section>
      <section className="footer__link-list">
        <h5 className="footer__link-list-title">Company</h5>
        <ul role="list">
          <li>
            <a href="/about-launch-academy">About</a>
          </li>
          <li>
            <a href={Configuration.careersPageUrl}>Careers</a>
          </li>
          <li>
            <a href="//codecabulary.launchacademy.com/">Codecabulary</a>
          </li>
          <li>
            <a href="/blog">Blog</a>
          </li>
          <li>
            <ToastProvider placement="bottom-center">
              <ContactButton location={location} />
            </ToastProvider>
          </li>
        </ul>
      </section>
      <section className="footer__compliance">
        <ul role="list" className="footer__compliance-links">
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/student-statistics">Student Disclosures</a>
          </li>
        </ul>
        <p className="footer__copyright-notice">&copy; {new Date().getFullYear()} Launch Academy</p>
      </section>
    </footer>
  );
};

export default Footer;
