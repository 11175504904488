export default class Configuration {
  static get emailRegex() {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  }

  static get infoCallCalendlyUrl() {
    return "https://calendly.com/launch-academy/info-call?primary_color=6930c3";
  }

  static get consultingCallCalendlyUrl() {
    return "https://calendly.com/dan-at-launch-academy/custom-development-discovery?primary_color=6930c3";
  }

  static get careersPageUrl() {
    return "https://apply.workable.com/launch-academy-boston/";
  }

  static get applicationBaseUrl() {
    return "https://launchpass.launchacademy.com/enrollments/v1/locations/boston";
  }

  static get phoneNumber() {
    return "18447452862";
  }
}
