import React from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";

import { useKeypress } from "../../hooks/useKeypress";
import "./modal.css";

const Modal = ({
  children, isVisible, hide, size = "small", displayInBackground,
}) => {
  useKeypress("Escape", hide);

  return (
    <>
      {isVisible
        ? <Portal>
          <div
            onClick={() => hide()}
            className={displayInBackground ? "modal modal_display-background" : "modal"}
          >
            <div className="modal__overlay" />
            <div className="modal__wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
              <div
                onClick={(e) => e.stopPropagation()}
                className={`modal__contents ${size === "large" ? "modal__contents_large" : ""}`}
              >
                <div className="modal__header">
                  <button
                    type="button"
                    className="modal__close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </Portal>
        : null}
    </>
  );
};

Modal.defaultProps = {
  size: "",
  displayInBackground: false,
};

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  size: PropTypes.string,
  displayInBackground: PropTypes.bool,
};
export default Modal;
