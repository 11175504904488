/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

import { TopBar, Button, Icon } from "@launchacademy/voyager";
import Footer from "./footer";
// import logo from "@launchacademy/voyager/src/assets/images/logo.png"
import "@launchacademy/voyager/dist/css/src/assets/scss/main.css";
import "../css/main.css";
import "@launchacademy/voyager/dist/css/theme/navigation/topBar.css";
import "@launchacademy/voyager/dist/css/theme/buttons/buttons.css";

import logoImage from "@launchacademy/voyager/dist/images/logo.svg";
import Configuration from "../Configuration";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Layout = ({ children, location, primaryCta = null }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const logo = (
    <Link to="/">
      <img
        src={logoImage}
        width="240"
        height="48"
        className="navigation-list__logo"
        alt="Launch Academy"
      />
    </Link>
  );
  return (
    <>
      <TopBar logo={logo} logoAltText="Launch Academy" logoUrl="/">
        <ul className="navigation-list navigation-list__top" role="list">
          <li>
            <Link className="navigation-list__link" to="/program">
              Program
            </Link>
          </li>
          <li>
            <Link className="navigation-list__link" to="/admissions">
              Admissions
            </Link>
          </li>
          <li>
            <Link className="navigation-list__link" to="/why-launch-academy/success-stories">
              Why Launch
            </Link>
          </li>
          <li>
            <Link className="navigation-list__link" to="#">
              For Companies
              <Icon className="ml-2" icon={faChevronDown} />
            </Link>
            <ul className="navigation-list__sub-navigation">
              <li>
                <a
                  href="https://launchpass.launchacademy.com/hiring_portal"
                  className="navigation-list__link"
                >
                  Hire Grads
                </a>
              </li>
              <li>
                <Link className="navigation-list__link" to="/for-companies/custom-development">
                  Custom Development
                </Link>
              </li>
            </ul>
          </li>
          <li className="navigation-list__apply-list-item">
            {primaryCta || (
              <Button
                to={Configuration.applicationBaseUrl}
                size="md"
                text="Apply Today"
                icon={faChevronRight}
              />
            )}
          </li>
        </ul>
      </TopBar>
      <div className="main-content" style={{ paddingTop: "6rem" }}>
        {children}
        <Footer location={location} />
      </div>
      <div id="portal"></div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
