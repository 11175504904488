import React, { useMemo } from "react"
import useModal from "../../modal/useModal"
import Modal from "../../modal/Modal"
import ContactForm from "../ContactForm"
import { useToasts } from "react-toast-notifications"

const useContactModal = ({location} = {}) => {
  const { isModalVisible, setModalVisibility } = useModal({scrollToTop: false})
  const { addToast } = useToasts()
  const {search, state} = location || {}

  const modal = useMemo(() => {
    return <Modal size="large" isVisible={isModalVisible} hide={() => {
      setModalVisibility(false)
    }}>
      <ContactForm onSubmit={() => {
        setModalVisibility(false)
        addToast("Thanks! We'll be in touch", {appearance: "success"})
      }} />
    </Modal>
  }, [setModalVisibility])

  return {modal, isModalVisible, setModalVisibility}
}

export default useContactModal
