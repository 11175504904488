import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@launchacademy/voyager";
import Configuration from "../../Configuration";
import "../../css/forms.css"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const FooterOptInForm = () => {
  const { handleSubmit, register, formState: {errors}, setValue } = useForm()
  const [successMessage, setSuccessMessage] = useState()
  const onSubmit = async (data) => {
    if(window.dataLayer) {
      window.dataLayer.push({
        'event': 'optIn',
        'OptInFor': 'general-footer',
        'Email': data.email
      })
    }
    await fetch('/', {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data)
    })

    setSuccessMessage("Thank you! Watch your inbox for the latest updates.")
    setValue("email", "")
  }

  return (
    <form autoComplete="off" name="generalOptIn" data-netlify="true" noValidate="noValidate" className="form footer__form" onSubmit={handleSubmit(onSubmit)} autoComplete="off" netlify-honeypot="emailAddress">
      <fieldset>
        <legend className="footer__form-legend">Stay in the loop</legend>
        <input
          type="email"
          name="email"
          id="footer__input_type-email"
          className={`footer__input_type-email ${errors.email ? 'form__input_error' : ''}`}
          placeholder="Your email address"
          aria-label="Your email address"
          {...register("email", {required: true, pattern: Configuration.emailRegex })}
          required
        />
        {errors.email && <p className="form__error">Invalid email</p>}
        <button type="submit" className="footer__form-submit-button" aria-label="Subscribe">
          <Icon icon={faPaperPlane} />
        </button>
      </fieldset>
      <input type="hidden" value="generalOptIn" {...register("form-name")} />
      <p hidden>
        <label>ignore: <input value="" {...register("emailAddress", {validate: v => v === ""})} /></label>
      </p>
      {successMessage && <p>{successMessage}</p>}
    </form>
  );
};

export default FooterOptInForm;
